<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div
        class="
          card-title
          m-0
          w-100
          d-flex
          align-items-center
          justify-content-between
        "
      >
        <div
          class="d-flex align-items-center justify-content-start cursor-pointer"
          @click="backMenu"
        >
          <i class="bi bi-arrow-left h3 me-4 back-menu text-white"></i>
          <h3 class="fw-bolder m-0 back-menu text-white">
            Detail Pergerakan Caleg
          </h3>
        </div>
        <button class="float-end btn btn-sm btn-white">Ekspor PDF</button>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body border-top p-9">
      <div class="table-responsive">
        <table class="table table-row-bordered table-striped">
          <thead>
            <tr class="fs-6 fw-bolder border-bottom">
              <th>Kecamatan</th>
              <th>Kelurahan</th>
              <th>Alamat</th>
              <th>RT/RW</th>
              <th>Foto Kegiatan</th>
              <th>Kegiatan</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in data" :key="i">
              <td>{{ item.kecamatan }}</td>
              <td>{{ item.keluaran }}</td>
              <td>{{ item.adress }}</td>
              <td>{{ item.rt + "/" + item.rw }}</td>
              <td>
                <img
                  class="img-fluid"
                  style="max-height: 50px"
                  :src="item.picture"
                  alt="foto kegiatan"
                />
              </td>
              <td>{{ item.kegiatan }}</td>
              <td>{{ item.keterangan }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CryptoJS from "crypto-js";
import { move } from "@amcharts/amcharts4/.internal/core/utils/Array";
export default {
  props: ["id"],
  setup(props) {
    setCurrentPageTitle("Pergerakan Caleg");
    const path = window.location.origin + "/";
    const router = useRouter();
    const store = useStore();
    store.commit("SET_ACTIVE_MENU", "movement-map");

    const dataId = CryptoJS.AES.decrypt(props.id, "PKS.id").toString(
      CryptoJS.enc.Utf8
    );
    if (!dataId) {
      router.push({ name: "movement-map.chart" });
    }

    store.dispatch("AllMovementDashboard", { candidate: dataId });

    const data = ref([]);

    const movement = computed(() => store.state.MovementDashboardModule.all);

    watch(movement, () => {
      if (movement.value && movement.value.length) {
        movement.value.map((item) => {
          data.value.push({
            kecamatan: item.parent_territory.name ? item.parent_territory.name : "",
            keluaran: item.territory.name,
            adress: item.movement.alamat,
            rt: item.movement.rt,
            rw: item.movement.rw,
            picture: item.movement.foto,
            kegiatan: "",
            keterangan: item.movement.description,
          });
        });
      }
    });

    const backMenu = () => {
      // router.push({ name: "movement-map.chart" });
      router.go(-1);
    };

    return {
      data,
      backMenu,
    };
  },
};
</script>
